<template>
  <div class="widgetContainer widgetContainer--new full-width admin-dashboard">
    <div class="widgetContainer__body">
      <p class="header-1">Admin Dashboard</p>

      <el-form
        class="wise-form"
        ref="cardDetail"
        :model="formData"
        :rules="formRules">
        <el-form-item
          prop="personId"
          :label="$t('personId')">
          <div class="el-input">
            <input type="text" autocomplete="off" name="personId" v-model="formData.personId" class="el-input__inner">
          </div>
        </el-form-item>

        <el-form-item
          prop="fsDealerID"
          :label="$t('fsDealerId')">
          <div class="el-input">
            <input type="text" autocomplete="off" name="fsDealerID" v-model="formData.fsDealerID" class="el-input__inner">
          </div>
        </el-form-item>
      </el-form>

      <el-button data-testid="submit" type="primary" @click="submit" class="el-button__brand brand width-100" :disabled="!isFormValid">
        {{ $t('save') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {getEnv} from '@/utils/env';

export default {
  name: 'AdminDashboard',
  data() {
    return {
      formData: {
        personId: '',
        fsDealerID: '',
      },
      formRules: {}
    };
  },
  computed: {
    isFormValid() {
      return (
        this.formData.personId !== '' &&
        this.formData.fsDealerID !== ''
      );
    }
  },
  methods: {
    ...mapActions('user', ['addFSDealerID', 'addFSDealerID_Patch']),
    submit() {
      localStorage.setItem('fsDealer', this.formData.fsDealerID);
      const loader = this.showLoader();
      const payload = {
        personId: this.formData.personId,
        url: getEnv('VUE_APP_API_BASE_URL'),
        params: {
          metadata: {
            fsDealerID: this.formData.fsDealerID
          }
        }
      };

      this.addFSDealerID_Patch(payload)
        .then(() => {
          this.apiErrorMessage(this.$t('fsDealer_success'), 'success');
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          this.formData.personId = null;
          this.formData.fsDealerID = null;
          loader.close();
        });
    }
  }
}
</script>
