<template>
  <div class="widgetContainer widgetContainer--scrollable widgetContainer--center accountList">
    <div class="widgetContainer__body">
      <div class="accountList__list">
        <div v-for="b in getBusinessData" :key="b">
          <template v-if="getAccountsData[b].accounts.length !== 0">
            <div class="accountList__list__header">
              <p class="title">
                {{ getAccountsData[b].businessDetails.legalName }}
              </p>
              <p class="total-amount">
                {{ getAccountsData[b].totalAmount.toFixed(2) | currency }}
              </p>
            </div>
            <ul>
              <li
                v-for="(account, index) in getAccountsData[b].accounts"
                :key="index"
                :class="{
                  selected:
                    getSelectedAccount && getSelectedAccount.id === account.id
                }"
                @click="selectAccount(account, getAccountsData[b])">
                <div class="initialsInfoDark initialsInfoDark--square">
                  <span>{{ account.label[0] }}</span>
                </div>
                <div class="details">
                  <p class="accountName">
                    {{ account.label }}
                  </p>
                  <p class="amount">
                    {{ $t('dashboard_row_balance_title') }} {{
                      account.availableBalance | currency
                    }}
                  </p>
                  <!-- <p class="description">{{account.label}} {{account.accountNumber}}</p> -->
                </div>
                <!-- <div class="action">
                  <span
                    v-if="pageType === 'initial'"
                    class="icon-Arrow-small-right back-action" />
                  <template v-else>
                    <span class="icon-unselected unselected" />
                    <span class="icon-selected selected">
                      <span class="path1" />
                      <span class="path2" />
                    </span>
                  </template>
                </div> -->
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div class="widgetContainer__footer--fixed"
           v-if="!hideAddAccountButton">
        <el-button
          type="primary"
          @click="addAccount()"
          class="el-button__brand brand">
          {{ $t('buttn_addAccount') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import accountMixin from '../mixins/account';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { dummyBusinessId, getAccountsGroup } from '@m/account/utils/account';
export default {
  name: 'AccountList',
  data() {
    return {
      pageType: 'initial'
    };
  },
  mixins: [accountMixin],
  created() {
    this.pageType = 'initial';
    if (this.$businessChecking) {
      const loader = this.showLoader();
      this.listAllBusiness()
        .then(() => {
          setTimeout(() => {
            if (
              this.getSelectedBusiness == null &&
              this.getBusinessList &&
              this.getBusinessList.length > 0
            ) {
              this.setSelectedBusiness(this.getBusinessList[0]);
            }
            this.fetchAllAccounts();
          }, 0);
        })
        .catch((e) => console.log(e))
        .finally(() => loader.close());
    } else {
      this.fetchAllAccounts();
    }
  },
  computed: {
    ...mapGetters(['getIsMobileLayout']),
    ...mapGetters('account', ['getAllAccount', 'isBusinessAccountType']),
    ...mapGetters('kyb', ['getBusinessList', 'getSelectedBusiness']),
    getAccountsData() {
      return getAccountsGroup(
        this.getAllAccount,
        this.getBusinessList,
        this.$personalChecking,
        this.$businessChecking
      );
    },
    getBusinessData() {
      //show personal checking at first place
      return Object.keys(this.getAccountsData).sort((a, b) =>  {
        if(a === dummyBusinessId) {
          return -1;
        } else if(b === dummyBusinessId) {
          return 1;
        }
        return 0
      });
    },
    layout() {
      return this.$route.meta && this.$route.meta.layout || 'dashboard'
    },
    hideAddAccountButton() {
      return (this.checkIfExistPersonalCheckingAccount || (this.layout === 'dashboard' && !this.getIsMobileLayout));
    }
  },
  methods: {
    ...mapActions('account', ['listAllAccounts']),
    ...mapMutations('account', ['setGroupedAccounts']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    ...mapMutations('card', ['updateCards']),
    ...mapActions('kyb', ['listAllBusiness']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    checkIfExistPersonalCheckingAccount() {
      return this.getBusinessData.some(businessData => this.getAccountsData[businessData].accounts.some(account => account.type === 'personalChecking'));
    },
    getFirstCheckingAccountAndSection() {
      let response = {
        checkingAccount: null,
        section: null,
      };
      this.getBusinessData.forEach(businessData => {
        const checkingAccount = this.getAccountsData[businessData].accounts.find(account => account.type === 'personalChecking');
        if (checkingAccount) {
          response = {
            checkingAccount,
            section: businessData,
          };
        }
      });

      return response;
    },
    fetchAllAccounts() {
      const loader = this.showLoader();
      this.listAllAccounts(null)
        .then(() => {
          const checkingAccountData = this.getFirstCheckingAccountAndSection();
          if (checkingAccountData.checkingAccount) {
            this.selectAccount(checkingAccountData.checkingAccount, this.getAccountsData[checkingAccountData.section]);
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    selectAccount(a, section) {
      this.setGroupedAccounts(section.accounts);
      this.setSelectedAccount(a);
      if(section && section.businessDetails && section.businessDetails.id) {
        this.setSelectedBusiness(section.businessDetails);
      }
      setTimeout(() => {
        this.setRecentTransactions([]);
        this.updateCards({
          data: [],
          total: 0
        });
        if (this.pageType === 'initial') {
          this.goToDashboard();
        }
      }, 0);
    },
    addAccount() {
      this.$router.push('/account/create');
    }
  }
};
</script>

<style lang="scss">
  .accountList {

    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;

      .back {
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon-arrow-left {
          font-size: 18px;
        }

        p {
          color: #3c3c43;
          font-size: 17px;
          padding-left: 6px;
        }
      }
    }

    &__list {
      margin-bottom: 32px;

      > div {
        padding-bottom: 12px;
      }

      &__header {
        color: var(--branding);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        line-height: 18px;

        .title {
          padding-right: 8px;
        }
      }

      ul {
        li {
          background: #fff;
          border-radius: var(--radius);
          padding: 16px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          border: 1px solid var(--branding-sidebar-border);;
          transition: 0.2s;
          cursor: pointer;

          &:hover {
            border-color: var(--branding);
          }

          .initialsInfoNew {
            background-color: rgba(#000000, 0.4);
          }

          .details {
            margin: 0 auto 0 15px;
            padding-right: 8px;

            .accountName {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
            }

            .amount {
              font-size: 12px;
              line-height: 16px;
              color: var(--branding);
            }

            .description {
              color: rgba(#3c3c43, 0.6);
              font-size: 12px;
              padding-top: 2px;
            }
          }

          .action {
            cursor: pointer;
          }

          .selected,
          .unselected {
            font-size: 20px;
          }

          .selected {
            display: none;
          }

          &.selected {
            cursor: pointer;
            border-color: var(--branding);

            .initialsInfo {
              background-color: var(--branding);
            }

            .unselected {
              display: none;
            }

            .selected {
              display: block;
            }
          }
        }
      }
    }

    &.dashboard-router-view {
      background-color: var(--branding-widget-container);
      max-width: 100%;
      margin: 0 0 0 289px;
      height: 100vh;
      padding: 105px 60px 0 32px;
      position: static;
      transform: none;

      @media screen and (max-width: 992px) {
        padding: 0 32px 0 32px;
        margin: 0;
        height: calc(100vh - 68px);
      }

      @media screen and (max-width: 768px) {
        padding: 0;
      }

      .widgetContainer__body {
        padding-top: 40px;

        @media screen and (max-width: 992px) {
          padding-bottom: 0;
        }
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        @media screen and (max-width: 768px) {
          gap: 8px;
        }

        @media screen and (max-width: 992px) {
          grid-template-columns: 1fr;
        }

        li {
          margin: 0;
        }
      }

      .accountList__list  {

        padding-bottom: 80px;

        & > div {
          padding-bottom: 40px;

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        &__header {
          position: static;
          text-transform: none;
          padding-bottom: 24px;

          @media screen and (max-width: 768px) {
            padding-bottom: 16px;
          }

          .title {
            font-size: 24px;

            @media screen and (max-width: 768px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
    
          .total-amount {
            color: var(--branding);
            font-weight: 700;
            font-size: 20px;

            @media screen and (max-width: 768px) {
              font-size: 14px;
              line-height: 18px;
              font-weight: normal;
            }
          }
        }
      }

      .widgetContainer__footer--fixed {
        position: static;

        @media screen and (max-width: 992px) {
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100vw - 96px);
        }

        @media screen and (max-width: 768px) {
          margin-top: 0;
          transform: none;
          left: 0;
          width: 100%;
          padding: 16px;
        }
      }
    }
  }
</style>
